import React, { Component } from 'react';
export default  class Resume extends Component {
  render() {
    return (
      <section id="resume">

          <div className="row work">
            <div className="three columns header-col">
               <h1><span>Arbeidserfaring</span></h1>
            </div>

            <div className="nine columns main-col">
                    <div key="RedRockSolutions" className="row item">
                       <div className="twelve columns">
                          <h3>Red Rock Solutions</h3>
                          <p className="info">Frontend utvikling, Team koordinering, Kundeoppfølging</p>
                       </div>
                    </div>

					<div key="Vest-agder-fylkeskommune" className="row item">
						<div className="twelve columns">
							<h3>Vest-agder fylkeskommune</h3>
							<p className="info">IT drift, Nettverk, 1. og 2. linje support</p>
						</div>
					</div>

					<div key="Norsk-miljosanering-AS" className="row item">
						<div className="twelve columns">
							<h3>Norsk miljøsanering AS</h3>
							<p className="info">Forman og rivningsarbeider</p>
						</div>
					</div>

					<div key="Rencon-AS" className="row item">
						<div className="twelve columns">
							<h3>Rencon AS</h3>
							<p className="info">Produksjonsleder og salg av betongprodukter til bedriftsmarkedet</p>
						</div>
					</div>
            </div> 
         </div>

         <div className="row education">

            <div className="three columns header-col">
               <h1><span>Utdanning</span></h1>
            </div>

            <div className="nine columns main-col">
      
                    <div key="Noroff" className="row item">
						<div className="twelve columns">
							<h3>Noroff</h3>
							<p className="info">Frontend utvikler</p>
						</div>
                    </div>

                    <div key="KvadraturenVGS" className="row item">
                       <div className="twelve columns">
							<h3>Kvadraturen VGS</h3>
							<p className="info">IKT Driftsfag</p>
                       </div>
                    </div>
            </div>
         </div>
      </section>
    );
  }
}