import React, { Component } from 'react';

export default class Hero extends Component {
  render() {
    return (
        <section id="hero">
            <div className="row banner">
                <div className="banner-text">
                    <h1 className="responsive-headline">minPortfolio.</h1>
                    <h3 style={{ color: '#fff', fontFamily: 'sans-serif ' }}>Mitt navn er Tore André Rosander.</h3>
                    <h3 style={{ color: '#fff', fontFamily: 'sans-serif ' }}>Jeg er 35 år og jobber som Frontend utvikler i Red Rock Solutions.</h3>
                <hr />
                <ul className="social">

                        <li key="linkedin">
                            <a href="https://www.linkedin.com/in/tore-andre-rosander-141019b9/" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-linkedin"></i>
                            </a>
                        </li>
                            <li key="github">
                            <a href="https://github.com/ToreAndreRosander" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-github"></i>
                            </a>
                        </li>
                        <li key="epost">
                        <a href="mailto:tore@rosander.no" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-envelope"></i>
                        </a>
                        </li>
                    
                </ul>
                </div>
          </div>

          <p className="scrolldown">
            <a className="" href="#about">
              <i className="icon-down-circle"></i>
            </a>
          </p>
      </section>
    );
  }
}