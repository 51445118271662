import React, { Component } from 'react';

export default class About extends Component {
   
  render() {
    return (
      <section id="about">
         <div className="row">
            <div className="three columns">
               <img className="profile-pic"  src="images/profilepic.jpg" alt="" />
            </div>

            <div className="eight columns main-col">
               <h2>Om meg</h2>
               <p className="custom-p">Jeg har jobbet 5 år i Red Rock Solutions hvor jeg startet i helpdesken, før turen gikk videre til test/QA, litt team koordinering og nå frontend utvikler. Arbeidsoppgavene mine inkluderer hele utviklingsløpet av programvaren vi produserer, fra kravspesifikasjon med kunde, design, utvikling og fremdrift, til ferdig leveranse.<br /><br/>
                  Mine favoritt prosjekter er utvikling av brukervennlige interaktive frontendløsninger som gir verdi til brukeren og produkteier.<br />
                  Som utvikler jobber jeg mye mot Entur sine API-er og integrasjoner mot private og offentlige selskaper innenfor kollektivsektoren.</p>
               <br/>
               <p className="custom-p">Ved siden av jobb er jeg helt i innspurten på Frontend studiene på Noroff og liker å eksperimentere med moderne brukergrensesnitt som bla. VR/AR, Computer vision, AI/Maskinlæring og hardwareplatformer som arduino og raspberry PI.</p>
               <br/>
               <h3 className="custom-p" style={{color: 'white'}}>Nøkkelkunnskaper</h3>
               <p className="custom-p">Javascript, PHP, HTML/CSS, Wordpress, WooCommerce, React, REST API, GraphQL, Jira, Mapbox, MySQL, Adobe pakken.</p>
               <br/>
               <h3 className="custom-p" style={{color: 'white'}}>Andre interesseområder</h3>
               <p className="custom-p">OpenCV, AI, Ardunio, Smarte byer, Automatisering, Trening og Friluftsliv.</p>
            </div>
         </div>
      </section>
    );
  }
}