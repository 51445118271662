import { useState } from 'react';
import Popup from './Popup';
import 'reactjs-popup/dist/index.css';

function Portfolio(props) {
  const [popupOpen, setPopupOpen] = useState({
    popup1: false,
    popup2: false,
    popup3: false,
    popup4: false,
    popup5: false,
    popup6: false,
  });

  const openPopup = (popupKey) => {
    setPopupOpen((prevPopupOpen) => ({ ...prevPopupOpen, [popupKey]: true }));
  };

  const closePopup = (popupKey) => {
    setPopupOpen((prevPopupOpen) => ({ ...prevPopupOpen, [popupKey]: false }));
  };

  let resumeData = props.resumeData;
  return (
    <section id="portfolio">
		<div className="row">
			<div className="twelve columns collapsed">
			<h1>Mine sideprosjekter</h1>
			<div id="portfolio-wrapper" className="portfolio-main">

				<div className="portfolio-card" onClick={() => openPopup('popup4')}>
					<img src="images/portfolio/cityride-hero.jpg" className="item-img" />
					<div className="portfolio-card-container">
					<h5>Cityride</h5>
					<p>Frontend for mikromobilitet og smarte byer.</p>
					<p><b>HTML, CSS, JS, PHP, MapBox, GraphQL</b></p>
					</div>
				</div>

				<div className="portfolio-card" onClick={() => openPopup('popup1')}>
					<img src="images/portfolio/ordleken-2.jpg" className="item-img" />
					<div className="portfolio-card-container">
					<h5>Ordleken</h5>
					<p>Ordspill skrevet i vanilla javascript.</p>
					<p><b>HTML, CSS, JS, PHP, MYSQL</b></p>
					</div>
				</div>

				<div className="portfolio-card" onClick={() => openPopup('popup2')}>
					<img src="images/portfolio/rainydays-hero.jpg" className="item-img" />
					<div className="portfolio-card-container">
					<h5>Rainydays</h5>
					<p>Nettbutikk Frontend med protyping i XD.</p>
					<p><b>HTML, CSS, JS, ADOBE XD</b></p>
					</div>
				</div>

				<div className="portfolio-card" onClick={() => openPopup('popup3')}>
					<img src="images/portfolio/mountaingoat-hero.jpg" className="item-img" />
					<div className="portfolio-card-container">
					<h5>MountaingGoat</h5>
					<p>Blogg frontend skrevet i javascript, headless WP CMS.</p>
					<p><b>HTML, CSS, JS, PHP, WP, Rest API</b></p>
					</div>
				</div>

				<div className="portfolio-card" onClick={() => openPopup('popup5')}>
					<img src="images/portfolio/funkysocks-hero.jpg" className="item-img" />
					<div className="portfolio-card-container">
					<h5>Funkysocks</h5>
					<p>Webshop med tilpasset fronend.</p>
					<p><b>HTML, CSS, JS, PHP, WP + WOO</b></p>
					</div>
				</div>

				<div className="portfolio-card" onClick={() => openPopup('popup6')}>
					<img src="images/portfolio/tic-hero.jpg" className="item-img" />
					<div className="portfolio-card-container">
					<h5>theImageCollector</h5>
					<p>Søk og last ned samlinger med bilder.</p>
					<p><b>ReactJS, Rest API, HTML, CSS</b></p>
					</div>
				</div>
            </div>
        </div>
    </div>

	<Popup isOpen={popupOpen.popup6} onClose={() => closePopup('popup6')}>
		<div className="white-popup">
			<img src="./images/portfolio/tic-hero.jpg" /><div className="white-popup-cont"><h3>theImageCollector</h3>
			<p><b>Søk og last ned en samling bilder uten å måtte gå inn på hvert enkelt bilde i bildebankene.</b></p>
			<br /><p>theImageCollector er en react app som benytte Pixabay sitt API for bildesøk. Søkefilteret lar deg spesifisere flere parametere for å begrense søket.</p>
			<br /><p>Markerte bilder blir lagret i et array sammen med detaljer om fotograf og direktelink til bildet hos Pixabay.</p>
			<br /><p>Når brukeren trykker "Last ned" vil det generes en .zip fil med alle de merkerte bildene i et brukbart webformat (bredde 640px, filstørrelser fra 150kb til 250kb). Metadata om fotograf og direktelink til bildet lagres i en .json fil sammen med bildefilene.</p>
	
			<br /><p>Se prosjektet live på <a href="https://rosander.no/theimagecollector/" target="_blank">theImageCollector</a></p>
			</div>
		</div>
	</Popup>

	<Popup isOpen={popupOpen.popup1} onClose={() => closePopup('popup1')}>
		<div className="white-popup">
			<img src="./images/portfolio/ordleken-2.jpg" /><div className="white-popup-cont"><h3>Ordleken.no</h3>
			<p><b>Et ord- og stavespill hvor du skal lage ord av bokstavene du blir tildelt.</b></p>
			<br /><p>Spillet starter med ord på to bokstaver. Du må stave 5 riktige ord for du kommer til neste nivå hvor det blir lagt til én bokstav.</p>
			<br /><p>Du får like mange poeng som det er bokstaver i ordet du staver riktig, du får også 10 ekstra sekunder.</p>
			<br /><p>Ordleken består av en MySQL database, et API skrevet i PHP og en frontend skrevet i vanilla javascript. For hvert nivå gjøres det et kall til API-et som henter alle ord med likt antall bokstaver som nivået tilsvarer.</p>
			<br /><p>Databasen består av i overkant 200 000 ord som er importert fra den Norske ordboken og Norges scrabble forbund.</p>
			<br /><p>Se prosjektet live på <a href="https://ordleken.no" target="_blank">ordleken.no</a></p>
			</div>
		</div>
	</Popup>

	<Popup isOpen={popupOpen.popup2} onClose={() => closePopup('popup2')}>
		<div className="white-popup">
			<img src="./images/portfolio/rainydays-hero.jpg" /><div className="white-popup-cont"><h3>Rainydays</h3>
			<p><b>Halvårsinnlevering på frontend studiene på Noroff. Rainydays er en frontend skrevet med HTML/CSS og vanilla javascript. Som backend benyttes Wordpress/WooCommerce og REST APIer mot frontend.</b></p>
			<br /><p>Prosjektet startet med en interaktiv prototype i AdobeXD og en styletile for farger og grafiske elementer. Deretter ble prototypen skrevet i HTML/CSS og javascript for å hente produkter og omtaler fra WP/WOO backend.</p>
			</div>
		</div>
	</Popup>

	<Popup isOpen={popupOpen.popup3} onClose={() => closePopup('popup3')}>
		<div className="white-popup">
			<img src="./images/portfolio/mountaingoat-hero.jpg" /><div className="white-popup-cont">
			<h3>MountainGoat</h3>
			<p><b>Førsteårs eksamen på frontend studiene på Noroff. MountainGoat er en moderne og minimalistisk adventure blogg med fokus på en elegant visuell fremstilling av bilder og innhold.</b></p>
			<br /><p>Prosjektet er skrevet med HTML/CSS/Javascript og er en frittstående frontend til en headless wordpress backend hvor du kan opprette nye innlegg. Frontend benytter REST API for å hente innlegg og poste/lese kommentarer.</p>
			<br /><p>Se prosjektet live på <a href="https://rosander.no/mountaingoat/" target="_blank">Rosander.no/mountaingoat/</a></p>
			</div>
		</div>
	</Popup>

	<Popup isOpen={popupOpen.popup4} onClose={() => closePopup('popup4')}>
		<div className="white-popup">
			<img src="./images/portfolio/cityride-hero.jpg" /><div className="white-popup-cont"><h3>Cityride.no</h3>
			<p><b>En gratis tjeneste som samler smart og bærekraftig mobilitet i én og samme app/tjeneste. Cityride synkroniserer data fra over 50 000 datapunkter flere ganger hvert minutt og viser sanntids data fra elektriske sparkesykkler, bysykkler, kollektivtrafikk, bildelingstjenester og el-bil ladere.</b></p>
			<br /><p>Dataen hentes via forskjellige typer APIer fra flere kilder. Flere spesialtilpassede PHP script henter, vasker, formaterer og lagrer dataen for bruk i frontend.</p>
			<br /><p>Frontend benyttes mapbox sine karttjenester og APIer, mens javascript benyttes for interaktiv funksjonalitet.</p>
			<br /><p>Cityride skal bli en komplett og skreddersydd frontend for bærekraftig og personlig mobilitet og gjøre smarte valg enklere for alle.</p>
			<br /><p>Se prosjektet live på <a href="https://cityride.no" target="_blank">cityride.no</a></p>
			</div>
		</div>
	</Popup>

      <Popup isOpen={popupOpen.popup5} onClose={() => closePopup('popup5')}>
      <div className="white-popup">
              <img src="./images/portfolio/funkysocks-hero.jpg" /><div className="white-popup-cont"><h3>Funkysocks.no</h3>
                <p><b>Funkysocks.no er en nettbutikk bygget med wordpress og woocommerce. Nettbutikken har flere spesialtilpassede landingssider og inkluderer en abonnements tjeneste.</b></p>
               <br /><p>De egenutviklede template delene er skrevet med Javascaript og PHP, og inkluderer flere tilpassninger i ordpresessen backend. Nettbutikken har også flere søkemotoroptimaliseringer og har en stigende rangering på søkemotorene.</p>
               <br /><p>Funkysocks prosjektet inkuderer også mte bildebehandling og grafisk arbeid med Adobe photshop og lightroom.</p>
               <br /><p>Se prosjektet live på <a href="https://funkysocks.no" target="_blank">funkysocks.no</a></p>
                </div>
        </div>
      </Popup>


    </section>
  );
}

export default Portfolio;
